@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:wght@600&display=swap);
body {
  margin: 0;
  font-family: "Red Hat Text", sans-serif;
  color: #eee;
  background-color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #FFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Minter {
  max-width: 80vw;
  max-height: 80vh;
  padding: 3vh 10vw;
  margin: 0 auto;
  text-align: center;
}

input {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #222;
  color: #111;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}

button {
  padding: 9px 16px;
  max-height: 40px;
  color: #FFF;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
  font-family: "Montserrat";
  background-color: #301E98;
  border: 3px solid #4733b6;
}

#wallet-bar {
  display: block;
  height: 50px;
}

.logo {
  width:50px;
  height: 50px;
  float: left;
  background-color: #000;
}

.logo img{
  height: 50px;
}

.heroimg {
  text-align: center;
}
.heroimg img {
  width: 80vw;
}

.price {
  color:#eee;
  font-weight: 800;
}

#title {
  font-family: "Montserrat";
  font-weight: 800;
}

#mintSupplyStatus {
  color:#eee;
  margin: 10px 0;
  font-weight: 800;
}

#status {
  color:#eee;
  margin: 10px 0;
  font-weight: 600;
}

#alert {
  color:#eee;
  margin: 10px 0;
  font-weight: 600;
}

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

#walletButton {
  text-align: right;
  float:right;
  margin-bottom: 30px;
  line-height: 16px;
}

#mintButton {
  color:white;
}

#mint-form {
  visibility: hidden;
  height: 0;
}

#claim-form {
  visibility: hidden;
  height: 0;
  /*margin-top: 40px;*/
  /*padding-top: 20px;*/
  /*padding-bottom: 40px;*/
  /*border-top: 1px solid #ddd;*/
}

#check-holders-form {
  visibility: hidden;
  height: 0;
}

#smart-contract {
  font-size: small;
  margin-top: 50px;
  margin-bottom: 20px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

